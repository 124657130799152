<template>
  <div>
    <div class="my-6">
      <h3 class="font-bold">
        Create a new {{ route.name.split("/")[0].slice(0, -1).toLowerCase() }}
      </h3>
      <span class="text-sm text-gray-500">Fill the following information and click the <b>create</b> button to
        save changes.
      </span>
    </div>

    <div class="card shadow-sm bg-white border p-8">
      <span class="lg:inline-grid w-full grid-cols-2 gap-x-4 gap-y-4 mb-6">
        <div class="flex-1 mr-3">
          <Input v-model="data.name" type="default" label="Name" placeholder="Project Name" />
        </div>
        <div class="flex-1 mr-3">
          <Input v-model="data.sprint" type="default" label="Sprint" placeholder="Sprint" />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.status" type="select" label="Status" placeholder="Status" :data="status.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.start" type="date" label="Start Date" placeholder="Start Date" />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.finish" type="date" label="Finish Date" placeholder="Finish Date" />
        </div>


        <Input v-model="data.required_hours" type="default" label="Required Hours" placeholder="Hours" />
        <div class="flex-1 mr-3">
          <Input v-model="data.client" type="default" label="Client Account" placeholder="Client Account" />
        </div>
        <div class="flex-1 mr-3">
          <Input v-model="data.manager" type="select" label="Manager" placeholder="Manager" :data="managers.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>
        <div class="flex-1 mr-3">
          <Input v-model="data.coordenator" type="select" label="Coordinator" placeholder="Coordinator" :data="coordenators.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.clientId" type="select" label="Client" placeholder="Client" :data="clients.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.sector" type="default" label="Sector" placeholder="Sector" />
        </div>
      </span>




      <div class="w-full grid-cols-2 gap-x-4 gap-y-4 mb-6">
        <div class="flex flex-col relative auto border rounded-lg bg-white appearance-none py-4">
          <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm">
            Modelers</span>
          <div class="flex mr-3" v-for="item in users" :key="item.name">
            <div class="block flex items-center h-5 ml-2">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                :id="item.id" :value="item.id" v-model="modelators" />
            </div>
            <div class="ml-1 text-sm">
              <label :for="item.value" class="font-medium text-gray-700">
                {{ `${item.name} - ${item.user_function}` }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-5">
      <Button @click="create" color="primary" text="Create" />
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, POST } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRoute, useRouter } from "vue-router";
import { formatDateToYYYYMMDD } from '../../services/formater'

export default {
  name: "createProjects",
  components: {
    Input,
    Button,
  },

  setup() {
    const alertProps = inject("alert");
    const data = ref({
      name: "",
      sprint: "",
      required_hours: "",
      client: "",
      coordenator: 0,
      manager: 0,
      clientId: 0,
      start: formatDateToYYYYMMDD(new Date()),
      finish: formatDateToYYYYMMDD(new Date()),
      sector: '',
      status: 0
    });
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();
    const managers = ref([]);
    const users = ref([]);
    const coordenators = ref([]);
    const modelators = ref([]);
    const clients = ref([]);
    const status = ref([]);

    onMounted(async () => {
      loader.open();
      try {
        const res = await GET("users");

        status.value = await GET('status')




        users.value = res.filter((el) => el.role === 2).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
        managers.value = res.filter((el) => el.role === 3);
        coordenators.value = res.filter((el) => el.role === 4);
        clients.value = res.filter((el) => el.role === 5);
        loader.close();
      } catch (e) {
        loader.close();
        if (
          e.includes("Token") ||
          e.includes("t_dynamik") ||
          e == "Unauthorized"
        ) {
          localStorage.removeItem("t_dynamik");
          localStorage.removeItem("info_dynamik");
          router.push({ path: "/login" });
        }
        alert.open("Error", e, "danger");
      }
    });

    const create = async () => {
      const obj = { name: data.value.name };
      const validate = isEmpty(obj);
      if (validate) {
        validate && alert.open("Atenção!", validate, "warning");
      } else {
        loader.open();
        try {
          const inputToHours = data.value.required_hours * 60;
          data.value.required_hours = inputToHours;
          data.value.manager = parseInt(data.value.manager);
          data.value.coordenator = parseInt(data.value.coordenator);

          const resposta = await POST("projects", data.value);

          const team = modelators.value.map((elemento) => {
            const body = {
              project: resposta.project.id,
              user: elemento,
            };

            return body;
          });

          await POST("team", team);

          alert.open("Success!", `Project successfully created!`, "success");
          loader.close();
          router.push("/projects");
        } catch (e) {
          alert.open("Attention!", e, "danger");
          loader.close();
          if (
            e.includes("Token") ||
            e.includes("t_dynamik") ||
            e == "Unauthorized"
          ) {
            localStorage.removeItem("t_dynamik");
            localStorage.removeItem("info_dynamik");
            router.push({ path: "/login" });
          }
        }
      }
    };

    return {
      alertProps,
      data,
      create,
      route,
      users,
      coordenators,
      managers,
      modelators,
      clients,
      status
    };
  },
};
</script>

<style></style>